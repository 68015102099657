<template>
  <div class="input" :class="disabled ? 'disable-input' : ''">
    <label v-if="dropdownLabel" class="input-label">
      {{ dropdownLabel }}
    </label>

    <div
      ref="container"
      :class="[
        containerClass,
        {
          'input-validation-error':
            validationErrorMessage || (serverError && serverError.length > 0),
        },
      ]"
      @click="onClick($event)"
    >
      <div class="p-hidden-accessible">
        <input
          :id="id"
          ref="focusInput"
          type="text"
          readonly
          :disabled="disabled"
          :tabindex="tabindex"
          aria-haspopup="listbox"
          :aria-expanded="overlayVisible"
          :aria-labelledby="ariaLabelledBy"
          @focus="onFocus"
          @blur="onBlur"
          @keydown="onKeyDown"
        />
      </div>
      <input
        v-if="inputEditable"
        :id="`input-${id}`"
        ref="focusInput"
        v-model="inputValue"
        type="text"
        :class="['p-dropdown-label', 'p-inputtext', `${pageName}-xedropdown-editable-input`]"
        :disabled="disabled || showEditableLoading"
        :placeholder="placeholder"
        aria-haspopup="listbox"
        :aria-expanded="overlayVisible"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInputEdit"
      />
      <span v-if="!inputEditable" :class="labelClass">
        <slot
          v-if="value"
          name="custom-value-placeholder"
          :selectedValue="value"
          :selectedLabel="label"
        >
          {{ label }}
        </slot>
        <span v-else class="select-placeholder">
          {{ placeholder }}
        </span>
      </span>
      <i
        v-if="showClear && value != null"
        class="p-dropdown-clear-icon pi pi-times"
        @click="onClearClick($event)"
      ></i>
      <div
        :class="[
          'p-dropdown-trigger',
          `${pageName}-xedropdown-arrow`,
          {
            'input-no-arrow': !showArrow,
            'input-validation-error':
              validationErrorMessage || (serverError && serverError.length > 0),
          },
        ]"
        role="button"
        aria-haspopup="listbox"
        :aria-expanded="overlayVisible"
      >
        <!--Ria Change Icon-->
        <AppIcon class="p-dropdown-trigger-icon pi pi-chevron-down" size="24">
          <IconChevronUp></IconChevronUp>
        </AppIcon>
      </div>

      <!-- Spinner Icon for editable to indicate loading state. -->
      <AppIcon
        v-if="inputEditable && !showArrow && showEditableLoading"
        class="spinner animate-spin"
        size="21"
      >
        <IconSpinner />
      </AppIcon>

      <Transition name="p-connected-overlay" @enter="onOverlayEnter" @leave="onOverlayLeave">
        <div
          v-if="overlayVisible"
          ref="overlay"
          class="p-dropdown-panel p-component"
          :class="{
            'p-dropdown-panel--bottom-up': isBottomUp,
          }"
          @click.stop
        >
          <div v-if="filter" class="p-dropdown-header">
            <div class="p-dropdown-filter-container">
              <input
                ref="filterInput"
                v-model="filterValue"
                type="text"
                autoComplete="off"
                class="p-dropdown-filter p-inputtext p-component"
                :placeholder="filterPlaceholder"
                @keydown="onFilterKeyDown"
                @input="onFilterChange"
              />
              <span class="p-dropdown-filter-icon pi pi-search"></span>
            </div>
          </div>
          <div
            ref="itemsWrapper"
            :class="['p-dropdown-items-wrapper', `${pageName}-xedropdown-list`]"
          >
            <ul v-if="options.length > 0" class="p-dropdown-items" role="listbox">
              <li
                v-for="(option, i) of options"
                :key="`${getOptionRenderKey(option)}-item-${i}`"
                :class="[
                  'p-dropdown-item',
                  {
                    'p-highlight': isSelected(option),
                    'p-disabled': isOptionDisabled(option),
                  },
                ]"
                :overflow="$checkOverflow(option)"
                :aria-label="getOptionLabel(option)"
                role="option"
                :aria-selected="isSelected(option)"
                @click="onOptionSelection($event, option)"
              >
                <AppRipple>
                  <slot
                    v-if="$slots['custom-option'] || $scopedSlots['custom-option']"
                    name="custom-option"
                    :option="option"
                  />

                  <div v-else>
                    <div v-if="option.overflow">
                      <div
                        :id="option.id"
                        class="dropdown-option tooltip-target"
                        :title="getOptionLabel(option)"
                      >
                        {{ getOptionLabel(option) }}
                        <span v-if="optionLabelSubtitle" class="dropdown-option-subtitle">
                          <br />{{ option[optionLabelSubtitle] }}
                        </span>
                      </div>
                    </div>

                    <div v-else>
                      <div :id="option.id" class="dropdown-option">
                        {{ getOptionLabel(option) }}
                        <span v-if="optionLabelSubtitle" class="dropdown-option-subtitle">
                          <br />{{ option[optionLabelSubtitle] }}
                        </span>
                      </div>
                    </div>
                  </div>
                </AppRipple>
              </li>
            </ul>

            <div
              v-if="options.length === 0 && (loading || noOptionsMessage)"
              class="p-dropdown-loader"
            >
              <!-- Default -->
              <div
                v-if="type === 'default' && loading"
                class="flex items-center justify-center w-full"
              >
                <AppIcon class="spinner animate-spin" :size="32">
                  <IconSpinner />
                </AppIcon>
              </div>
              <!-- Company -->
              <div
                v-if="type === 'company' && loading"
                class="flex items-center justify-start pl-3"
              >
                <div class="pr-3 pt-1">
                  <AppIcon class="spinner animate-spin" :size="32">
                    <IconSpinner />
                  </AppIcon>
                </div>
                <div class="flex-col">
                  <div class="font-medium">Searching the registry for your company</div>
                  <div class="type-caption-xs text-gray">
                    This process can take up to 60 seconds
                  </div>
                </div>
              </div>
              <div v-if="noOptionsMessage" class="flex items-center justify-center w-full">
                <span class="no-options-message">
                  {{ noOptionsMessage }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="$slots['custom-dropdown-footer-option']"
            :class="['p-dropdown-footer', `${pageName}-xedropdown-custom-footer`]"
          >
            <slot name="custom-dropdown-footer-option"></slot>
          </div>
        </div>
      </Transition>
    </div>

    <span v-if="validationErrorMessage" class="validation-error-message type-helper">
      {{ validationErrorMessage }}
    </span>
    <div v-if="serverError && serverError.length > 0">
      <p
        v-for="(error, index) in serverError"
        :key="index"
        style="white-space: pre"
        class="input-validation"
      >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'

import { ref } from '@vue/composition-api'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import { IconChevronUp, IconSpinner } from '@moneytransfer.ui/euronet-icons'

export default {
  name: 'XeInputDropdown',
  components: {
    AppIcon,
    IconChevronUp,
    IconSpinner,
  },
  extends: Dropdown,
  props: {
    inputEditable: {
      type: Boolean,
      default: false,
    },
    dropdownLabel: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noOptionsMessage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'default',
    },
    optionLabelSubtitle: String,
    pageName: {
      type: String,
      default: 'unknownpage',
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    showEditableLoading: {
      type: Boolean,
      default: false,
    },
    validationErrorMessage: {
      type: String,
      default: '',
    },
    serverError: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isBottomUp: false,
    }
  },
  methods: {
    onInputEdit: function (text) {
      this.onEditableInput(text)
      this.show()
    },
    onOptionSelection: function ($event, option) {
      this.onOptionSelect($event, option)
      this.onBlur()
    },
    show: function () {
      Dropdown.methods.show.call(this)
      window.requestAnimationFrame(() => {
        this.updateItemsWrapperHeight()
      })
    },
    setInputValue: function (value) {
      this.inputValue = value
    },
    updateItemsWrapperHeight: function () {
      const primeDropdownRef = {
        value: this,
      }

      const getDropdownHeight = () => {
        const { itemsWrapper } = primeDropdownRef.value.$refs
        if (!itemsWrapper) {
          return
        }
        return itemsWrapper.getBoundingClientRect().top + itemsWrapper.offsetHeight
      }
      const { container } = primeDropdownRef.value.$refs

      const containerTop = container.getBoundingClientRect().top
      const containerBottom = containerTop + container.offsetHeight

      let viewportTop = 0 // Top of current viewport
      let viewportHeight = window.innerHeight // Total height of current viewport

      // Check if component is inside an app card
      // When component is inside of an app card update viewport top and height
      const { parentAppCard } = primeDropdownRef.value
      if (parentAppCard && mq.current === 'xs') {
        const appCardContent = parentAppCard.$el.querySelector('.card-content')
        if (appCardContent) {
          viewportTop = appCardContent.getBoundingClientRect().top
          viewportHeight = appCardContent.offsetHeight
        }
      }

      // Calculate distances from container and dropdown max height
      const distanceToTop = containerTop - viewportTop
      const distanceToBottom = viewportHeight - containerBottom
      const dropdownHeight = containerBottom + Math.min(400, getDropdownHeight())

      // Dropdown should display upwards if available viewport space above container
      // is greater than a available viewport space below container
      const isBottomUp = dropdownHeight > viewportHeight ? distanceToTop > distanceToBottom : false
      primeDropdownRef.value.isBottomUp = isBottomUp

      // Calculated new height for dropdown items
      const itemHeight = (isBottomUp ? distanceToTop : distanceToBottom) - 24 // 24px is tailwind mb-6/mt-6

      // Avoid overlapping issues in the dropdown list when height size is less than 300px
      if (viewportHeight < 360) {
        primeDropdownRef.value.isBottomUp = false
      }

      // Only override css max item height if calculated height is less that 400
      // 400px is css max height
      if (itemHeight < 400) {
        // Set a min item height of 2x container height (96px)
        const minItemHeight = (containerBottom - containerTop) * 2
        primeDropdownRef.value.itemsWrapperHeight = Math.max(minItemHeight, itemHeight) + 'px'
      }
    },
    $checkOverflow: function (option) {
      this.$nextTick(function () {
        if (option != null && option.overflow == null) {
          const element = document.getElementById(option.id)
          if (element) {
            const overflow = element.scrollWidth > element.clientWidth
            if (overflow) {
              option.overflow = true
              this.$forceUpdate()
              return true
            } else {
              option.overflow = false
              return false
            }
          }
        }
      })
    },
  },
  setup() {
    const inputValue = ref('')

    return {
      inputValue,
    }
  },
}
</script>

<style lang="postcss" scoped>
.input {
  display: flex;
  flex-direction: column;
}

.p-dropdown {
  @apply rounded-lg;
  @apply p-3;
  @apply border border-blue-lighter;
  @apply w-full;
  @apply items-center;
  box-shadow: 0px 3px 15px rgba(0, 17, 51, 0.05);

  &.input-validation-error {
    @apply border-red;
  }

  &.p-focus {
    @apply border-blue;
    @apply shadow-outline-input-orange;
    @apply border;
  }

  .p-dropdown-trigger {
    &.input-validation-error {
      @apply text-red;
    }

    .pi-chevron-down {
      transform: rotate(-180deg);
      transition: all 270ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &[aria-expanded='true'] {
      &.input-validation-error {
        @apply text-red;
      }
      @apply text-blue-text;
      .pi-chevron-down {
        transform: rotate(0deg);
      }
    }
  }

  /* This input should only target editable input. */
  input {
    @apply border-none outline-none;
  }

  .p-dropdown-label {
    @apply leading-6;
    @apply text-base;
    @apply text-primary-text;

    &::placeholder,
    &.p-placeholder {
      @apply text-tertiary-text;
    }
  }

  .p-dropdown-panel {
    @apply text-left;
    @apply mt-2;
    @apply border border-transparent rounded-lg;
    @apply bg-white;
    @apply top-12 !important;
    max-width: 350px;
    @screen xs {
      max-width: 100%;
    }
    box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.15);

    &.p-dropdown-panel--bottom-up {
      @apply bottom-14;
      top: unset !important; /* No tailwind equivalent */

      @screen sm {
        .p-dropdown-items-wrapper {
          @apply bottom-0;
        }
      }
    }

    .p-dropdown-items-wrapper {
      @apply rounded-lg;
      @apply h-auto !important;
      max-height: 300px;

      .p-dropdown-item .app-ripple {
        @apply px-4 py-2.5;
        @apply type-caption font-medium;

        &:hover {
          @apply bg-gray-bg;
        }

        .dropdown-option {
          @apply truncate;
        }

        .dropdown-option-subtitle {
          @apply type-caption-xs text-gray;
        }
      }
    }

    .p-dropdown-items {
      @apply rounded-lg;
    }
  }

  .p-dropdown-loader {
    @apply h-20 w-full;
    @apply flex;

    .no-options-message {
      @apply text-tertiary-text;
      @apply text-center;
    }
  }

  .p-dropdown-footer {
    @apply border-t border-blue-light;
  }

  .input-no-arrow {
    @apply hidden;
  }
}

.validation-error-message {
  @apply text-red-text;
  @apply mt-1;
}

svg.spinner {
  @apply text-blue !important;
}
</style>

<style lang="postcss" global>
.trigger {
  @apply block !important;
}

.tooltip {
  @apply block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  @apply max-w-md;
  @apply bg-dark;
  @apply text-white;
  @apply rounded-sm;
  @apply py-0.5 px-1.5;
}

.tooltip .tooltip-arrow {
  @apply hidden;
}

.tooltip[aria-hidden='true'] {
  @apply invisible;
  @apply opacity-0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  @apply visible;
  @apply opacity-100;
  transition: opacity 0.15s;
}
</style>
